<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="所属租户"
    width="500px"
    top="3vh">
    <div>
      <el-form
        ref="form"
        :model="tenant"
        label-width="90px"
        style="margin-top: 20px">
        <div>
          <el-form-item label="所属租户" prop="companyId">
            <el-input v-model="tenant.tenantName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectTenantCom.open()"></el-button>
            </el-input>
            <tenant-select ref="selectTenantCom" @select="onSelectTenantCom"></tenant-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button type="primary" size="small" :loading="submitLoading" @click="saveTenantId">
          {{$t("common.save")}}
        </el-button>
    </span>
  </el-dialog>
</template>

<script>

  import TenantSelect from "@/views/tenant/TenantSelect";

  export default {
    components: {TenantSelect},
    props: {
      multiSelect: {
        type: Array,
      },
      actionType: {
        type: String,
      },
    },
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        tenant: {
          tenantId: 0,
          tenantName: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      saveTenantId() {
        let userIds = [];
        let elevatorIds = [];
        let companyIds = [];
        let useUnitIds = [];

        if (this.actionType === "user") {
          for (let item of this.multiSelect) {
            userIds.push(item.id);
          }
        }
        if (this.actionType === "elevator") {
          for (let item of this.multiSelect) {
            elevatorIds.push(item.id);
          }
        }
        if (this.actionType === "company") {
          for (let item of this.multiSelect) {
            companyIds.push(item.id);
          }
        }
        if (this.actionType === "useUnit") {
          for (let item of this.multiSelect) {
            useUnitIds.push(item.id);
          }
        }
        if (userIds.length === 0 && elevatorIds.length === 0 && companyIds.length === 0 && useUnitIds.length === 0) {
          this.$message.error("未选中任何设置项");
          return;
        }
        if (this.tenant.tenantId === 0) {
          this.$message.error("租户不能为空");
          return;
        }
        let params = {
          userIds: userIds,
          elevatorIds: elevatorIds,
          companyIds: companyIds,
          useUnitIds: useUnitIds,
          tenantId: this.tenant.tenantId,
        };
        this.$api.save("tenant/batchUpdate", params).then(res => {
          this.submitLoading = false;
          this.$emit("save-success");
          this.multiSelect = [];
          this.tenant = {
            tenantId: 0,
            tenantName: "",
          };
          this.dialogVisible = false;
          this.$message.success(this.$t("common.tip.saveSuccess"));
          this.$emit("save-success");
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      onSelectTenantCom(row) {
        console.log(row);
        this.tenant.tenantId = row.id;
        this.tenant.tenantName = row.name;
      },
      clearOrg() {
        this.tenant = {
          tenantId: 0,
          tenantName: "",
        };
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
