<template>
  <div v-loading="loading" class="vm-tree-wrapper">
    <div style="padding:10px 10px 0 10px">
      <div style="padding-bottom:10px;color: #666;font-size: 15px;display: flex">
        <span
          class="vm-ellipsis"
          style="width: 100%;">{{currentItem.name ? currentItem.name : $t("common.chooseAnArea")}}</span>
        <i
          v-if="currentItem.name"
          class="el-icon-delete"
          style="cursor: pointer"
          title="清除"
          @click="clear">
        </i>
      </div>
      <el-input v-model="filterText" :placeholder="$t('common.filter')"></el-input>
    </div>
    <div class="vm-tree-wrapper-inner" :style="{height: height}">
      <el-tree
        ref="tree"
        class="vm-tree-tree"
        :data="data"
        :filter-node-method="filterNode"
        :props="props"
        highlight-current
        :expand-on-click-node="false"
        @node-click="onNodeClick">
        <span slot-scope="{ node }" class="custom-tree-node">
          <span style="font-size: 14px;" :title="node.label" class="vm-ellipsis">{{node.label}}</span>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: "elevator",
      },
      height: {
        type: String,
      },
    },
    data() {
      return {
        loading: false,
        props: {
          label: "name",
        },
        data: [],
        filterText: "",
        currentItem: {},
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    mounted() {
      this.getTree();
    },
    methods: {
      getTree() {
        switch (this.type) {
          case "elevator":
          case "elevatorMonitor":
          case "useUnit":
          case "company":
            break;
          default:
            console.error("type仅支持elevator,elevatorMonitor,useUnit,company");
            break;
        }
        this.loading = true;
        this.$api.getList("tree/" + this.type).then(res => {
          this.loading = false;
          this.data = res.data;
        }).catch(error => {
          this.loading = false;
          this.$message.error("获取树失败，" + error.response.data.message);
        });
      },
      filterNode(value, data, node) {
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return data.name.indexOf(value) !== -1;
      },
      onNodeClick(data, node) {
        if (node.isLeaf) {
          this.currentItem = data;
          this.$emit("node-click", data);
        } else {
          this.currentItem = data;
          this.$emit("area-click", data);
        }
      },
      clear() {
        this.currentItem = {};
        this.$emit("node-clear");
      },
    },
  };
</script>

<style lang="scss" scoped>
.vm-tree-wrapper {
  border-radius: 12px;

  .vm-tree-wrapper-inner {
    max-height: calc(100vh - 158px);
    min-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ::v-deep .el-tree {
    background-color: inherit;
  }
}
</style>
