<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + $t('company.company')" :visible.sync="dialogVisible"
    width="80%" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="companies">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('company.type', '企业类型')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in companyTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('company.no')" min-width="60"></el-table-column>
      <el-table-column prop="name" :label="$t('company.name')" min-width="120"></el-table-column>
      <el-table-column prop="abbreviation" :label="$t('company.abbreviation')"></el-table-column>
      <el-table-column prop="brand" :label="$t('company.brand')" min-width="60"></el-table-column>
      <el-table-column :label="$t('company.type')" min-width="50">
        <template slot-scope="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column v-if="showOrgId" prop="orgName" :label="$l('elevator.belongCompany', '所属单位')"></el-table-column>
      <el-table-column v-if="showOrgId" prop="tenantName" label="租户" align="center"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="80"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
import { companyType } from "@/util/constant";
import { companyType_en } from "@/util/constant";
import auth from "@/util/auth";

export default {
  data() {
    return {
      showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
      dialogVisible: false,
      loading: true,
      extra: null,
      search: {
        filter: "",
      },
      companyTypeList: [
        { value: 1, label: this.$l("elevatorNetApply.createCom", "制造企业") },
        { value: 2, label: this.$l("elevatorNetApply.maintComName", "维保企业") },
        { value: 3, label: this.$l("elevator.installationEnterprise", "安装企业") },
        { value: 5, label: this.$l("maintWorkOrder.propertyCom", "物业公司") },
        { value: 8, label: this.$l("elevator.useUnit", "使用单位") },
      ],
    };
  },
  methods: {
    open(type, extra, filter) {
      if (type) {
        this.search.type = type;
      }
      this.dialogVisible = true;
      this.extra = extra;
      this.$nextTick(() => {
        if (filter) {
          this.search.filter = filter;
        }
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
    handleSelect(row) {
      this.$emit("select", row, this.extra);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
