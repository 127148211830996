<template>
  <el-dialog class="select-dialog" title="选择租户" :visible.sync="dialogVisible" width="80%" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tenant">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$l('contract.contractSource', '租户状态')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 120px" clearable>
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column prop="name" label="租户名称" align="center"></el-table-column>
      <el-table-column prop="status" label="租户状态" align="center" width="100px">
        <template v-slot="scope">
          <div v-if="scope.row.status === 1">
            冻结
          </div>
          <div v-if="scope.row.status === 0">
            正常
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="初始公司" align="center"></el-table-column>
      <el-table-column prop="username" label="用户名" align="center" width="200px"></el-table-column>
      <el-table-column prop="contact" label="联系人" align="center" width="110px"></el-table-column>
      <el-table-column prop="phone" label="手机号码" align="center" width="120px"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
import { companyType } from "@/util/constant";
import { companyType_en } from "@/util/constant";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      extra: null,
      search: {
        filter: "",
        type: 0,
      },
    };
  },
  methods: {
    open(type, extra, filter) {
      if (type) {
        this.search.type = type;
      }
      this.dialogVisible = true;
      this.extra = extra;
      this.$nextTick(() => {
        if (filter) {
          this.search.filter = filter;
        }
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
    handleSelect(row) {
      this.$emit("select", row, this.extra);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
